import React, { createContext } from 'react';
import HomeIcon from '@material-ui/icons/Home';
import MoveToInboxIcon from '@material-ui/icons/MoveToInbox';
import AssessmentIcon from '@material-ui/icons/Assessment';
import ContactsIcon from '@material-ui/icons/Contacts';
import ShareIcon from '@material-ui/icons/Share';
// this is the equivalent to the createStore method of Redux
// https://redux.js.org/api/createstore
export const AuthTokenContext = createContext();
export const LoanIdContext = createContext();

/*Constants*/
export const pages = [{
        key: "loans",
        link: "/",
        label: "Loans",
        tooltip: "Homepage",
        icon: <HomeIcon />
    },
    {
        key: "calculator",
        link: "/calculator",
        label: "Calculator",
        tooltip: "Affordability Calculator",
        icon: <AssessmentIcon />
    },
    // pages without link does not appear on the Drawer
    {
        key: "loan",
        link: null,
        label: "Loan",
        icon: <MoveToInboxIcon />
    },
    {
        key: "contacts",
        link: null,
        label: "Contacts",
        icon: <ContactsIcon />
    },
    {
        key: "userProfile",
        link: null,
        label: "User Profile",
    },
	{
        key: "share",
        link: "/share",
        label: "Share App Link",
        tooltip: "Share Application Link",
        icon: <ShareIcon />
    }
];

export const ALL_FILTER = 'ALL';