import LocalizedStrings from 'react-localization';
import axios from 'axios'

export function getLocalizedString() {
  axios.get('/rest/messages')
  .then(response => window.localized = new LocalizedStrings(response.data))
  .catch(error => console.log(error))
  .finally(() => {});
}

const formats = ({
  number: {
    usd: {
      style: 'currency',
      currency: 'USD'
    },
    raw_currency: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      useGrouping: true
    },
    rate: {
      style: 'decimal',
      minimumFractionDigits: 3,
      maximumFractionDigits: 3,
      useGrouping: true
    },
    decimal2: {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      useGrouping: true
    },
    percent: {
      style: 'percent',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      useGrouping: true
    }
  },
  date: {
  	option1: {  year: 'numeric', month: 'short', day: 'numeric' },
  	option2: {  year: 'numeric', month: 'numeric', day: 'numeric' }
  }
});

export const numberFormat = (number) => {
  return new Intl.NumberFormat('en-US', formats.number.usd).format(number);
}

export const dateFormat = (date) => {
  return new Intl.DateTimeFormat('default', formats.date.option1).format(new Date(date));
}

export const localize = (key) => {
  return window.localized[key] || key;
}

export const localizeFormat = (args) => {
  var s = localize(args[0])
  for (var i = 0; i < args.length - 1; i++) {       
    //Search for the param, "gm": global,multi-line
    var reg = new RegExp("\\{" + i + "\\}", "gm");             
    s = s.replace(reg, args[i + 1]);
  }
  return s || args[0];
}