import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { localize, PdfSend, ToastMessageContext, TodoContext } from "cnc-component-library";
import { getEmailTemplates, sendLoanEmail } from "../behaviors/RfcAjaxBehavior";
import SmsIcon from '@material-ui/icons/Sms';
import EmailIcon from '@material-ui/icons/Email';
import { AuthTokenContext } from "../behaviors/RfcContext";
import _ from 'lodash'

const SendPrequalButton = ({loanId}) => {

  const authToken = useContext(AuthTokenContext);
  const state = useRef(useContext(TodoContext));
  const toastMessage = useContext(ToastMessageContext);

  const [formContent, setFormContent] = useState({})
  const [formSubmitted, setFormSubmitted] = useState(false)
  const [formLoading, setFormLoading] = useState(false)
  const [hasTemplate, setHasTemplate] = useState(false)
  const defaultMessage = useRef(null)

  useEffect(() => {
    let mounted = true
    getRealtorTemplates(mounted);

    return () => {
      mounted = false
    }
  }, []);

  const debounceHandleUpdates = useCallback(_.debounce((formState, type) => {
    setFormContent(prev => ({
      ...prev,
      [type]: { recipient: formState.recipient, body: formState.body}
    }))
  }, 500), [])

  const getRealtorTemplates = (isMounted) => {
    const templates = ['RealtorPrequalEmail','RealtorPrequalSMS']
    getEmailTemplates(authToken, templates, response => {
      if (response.hasError) {
        toastMessage.current.setToastError(response)
      } else {
        actionData.forEach(action => {
          defaultMessage.current = {
            ...defaultMessage.current,
            [action.type]: response[action.type].body
          }
          if (isMounted) {
            setFormContent(prev => ({
              ...prev,
              [action.type]: { recipient: '', body: response[action.type].body}
            }))
          }
        })

        setHasTemplate(true)
      }
    })
  }

  const actionData = [
    {
      name: 'email',
      type: 'email',
      title: localize("realtor.prequal.email", "Email Pre-approval"),
      subTitle: localize("realtor.prequal.email.sub", "Please complete the To line and change/fill-in any text for the email and the pre-approval will be sent as an attachment with this email to your customer."),
      icon: <EmailIcon />,
      iconPosition: 96,
      snackbar: {
        style: { bottom: '100px'},
        message: localize('realtor.form.sendViaEmail', 'Send via Email'),
      },
      formState: {
        recipient: formContent?.email?.recipient,
        body: formContent?.email?.body,
      },
      handleUpdates: (data, type) => {
        debounceHandleUpdates(data, type)
      },
      handleSubmit: () => {
        sendForm("RealtorPrequalEmail", "email")
      },
    },
    {
      name: 'sms',
      type: 'sms',
      title: localize("realtor.prequal.sms", "SMS Pre-approval"),
      subTitle: localize("realtor.prequal.sms.sub", "Please complete the To line and change/fill-in the mobile number and the link containing the pre-approval will be sent as sms to your customer."),
      icon: <SmsIcon />,
      iconPosition: 160,
      snackbar: {
        style: { bottom: '162px'},
        message: localize('realtor.form.sendViaSms', 'Send via SMS')
      },
      formState: {
        recipient: formContent?.sms?.recipient,
        body: formContent?.sms?.body,
      },
      handleUpdates: (data, type) => {
        debounceHandleUpdates(data, type)
      },
      handleSubmit: () => {
        sendForm("RealtorPrequalSMS", "sms")
      },
    },
  ]

  const sendForm = (templateName, type) => {
    state.current.setTodoLoading(true)
    setFormLoading(true)
    setFormSubmitted(true)

    const formData = formContent[type]
    sendLoanEmail(authToken, loanId, templateName, formData, response => {
      state.current.setTodoLoading(false)
      setFormLoading(false)
      setFormSubmitted(false)

      if (response.hasError) {
        toastMessage.setToastError(response)
      } else {
        const bodyMsg = _.get(defaultMessage, `current.${type}`, "");
        if (_.isEmpty(bodyMsg)) {
          getRealtorTemplates(true)
        } else {
          setFormContent(prev => ({
            ...prev,
            [type]: { recipient: '', body: bodyMsg}
          }))
        }
        toastMessage.setToastSuccess(response[0])
      }
    })
  }

  return (
    hasTemplate && <PdfSend
      {...{formLoading, formSubmitted}}
      tooltip="Send Pre-approval"
      actions={actionData}
    />
  )
}

export default SendPrequalButton;