import React from 'react';
import withWidth from '@material-ui/core/withWidth';

class Page404 extends React.Component {
  render() {    return (
      <React.Fragment>
      	404 - Page Not Found
      </React.Fragment>
    );
  }
}

export default withWidth()(Page404);