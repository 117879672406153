import React,  { useContext, useEffect, useState, useRef } from 'react';
import { AuthTokenContext } from '../behaviors/RfcContext';
import { getKeycloakAccount, getUser } from "../behaviors/RfcAjaxBehavior";
import FileCopyIcon from '@material-ui/icons/FileCopy';
import EmailIcon from '@material-ui/icons/Email';
import SmsIcon from '@material-ui/icons/Sms';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Container from '@material-ui/core/Container';
import { makeStyles, withStyles } from '@material-ui/core/styles';  
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { ToastMessageContext, TodoContext, mobileCheck, getMobileOS, VideoLink } from 'cnc-component-library';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  urlButton: {
    padding: '0 6px'
  },
  disabledColor: {
    color: 'rgba(0, 0, 0, 0.26)'
  }
}));

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  }
}))(Tooltip);

const Share = props => {
  const classes = useStyles();
  const state = useRef(useContext(TodoContext));
  const toastMessage = React.useContext(ToastMessageContext);
  let authToken = useContext(AuthTokenContext);
  const [user, setUser] = useState(null)
  const [url, setUrl] = useState("")

  useEffect(() => {
    state.current.setTodoLoading(true);
    getKeycloakAccount(authToken, kcUser => {        
      getUser(authToken, (user) => {
        setUser(kcUser)
        setUrl(user.interactive1003Url);
      })
    });
  }, [])
  
  useEffect(() => {
    if (user) state.current.setTodoLoading(false)
  }, [user])

  const handleCopyToClipboard = (event) => {
    if (event) event.preventDefault();
    if (!!url) {
      navigator.clipboard.writeText(url);
      toastMessage.setToastSuccess("Successfully copied to clipboard.");
    } else {
      toastMessage.setToastError("Unable to perform. User has no Interactive 1003 Url. Please contact your Mortgage Analyst.");
    }
  };

  const handleSendToCustomer = (event, method) => {
    if (event) event.preventDefault();

    if (!!url) {
      let fullName = `${user.firstName} ${user.lastName}`;
      let subject = `Online Application referred by ${fullName}`;
      let body = 'Please use this link to complete an online application with Reliance First Capital:';
      if (method === 'EMAIL') {
        window.location.href = `mailto:?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}%0A${encodeURIComponent(url)}`;
      } else if (method === 'SMS') {
        const delimeter = getMobileOS() === 'Android' ? '?' : '&';
        window.location.href = `sms:${delimeter}body=${encodeURIComponent(body)}%0A${encodeURIComponent(url)}`;
      }
    } else {
      toastMessage.setToastError("Unable to perform. User has no Interactive 1003 Url. Please contact your Mortgage Analyst.");
    }
  };

  const ShareButton = (props) => {
    const { tooltip, ariaLabel, onClick, icon, isDisabled } = props
    return (
      <HtmlTooltip
        title={
          <React.Fragment>
            <Typography color="inherit" variant="subtitle2">{ tooltip }</Typography>
          </React.Fragment>
        }
      > 
        { 
          <IconButton
            aria-label={ ariaLabel }
            className={`${classes.urlButton} ${isDisabled ? classes.disabledColor : ''}`}
            onClick={!isDisabled ? onClick : (event) => { event.preventDefault(); return false; }}
          >
            { icon }
          </IconButton>
        }
      </HtmlTooltip>
    )
  }

  if (user === null) {
    return null;
  }

  return (
    <React.Fragment>
      <Container component="main" maxWidth="sm">
        <div className={classes.paper}>
          <div>
            <VideoLink url="https://youtu.be/XIUFNtujGNo" title="Tutorial" style={{paddingRight: '5px', position: 'absolute', marginTop: 3}}/>
            <Typography variant="subtitle2" style={{textIndent: 35, lineHeight: 2 }}>
              { "Reliance First Capital offers the opportunity for Realtors to send a link to prospective clients to submit an application electronically. When your client completes this application, we will know that this application is associated with you and you will be able to track the progress here on your home page in the Realtor Portal." }
            </Typography> 
          </div>
          <TextField
            margin="normal"
            fullWidth
            name="interactiveUrl"
            type="text"
            id="interactiveUrl"
            value={url}
            InputProps={{
              readOnly: true,
              endAdornment: <InputAdornment position="end">
                <ShareButton tooltip={'Copy link to Clipboard'} ariaLabel={'copy to clipboard'} onClick={handleCopyToClipboard} icon={<FileCopyIcon />} isDisabled={false} />
                <ShareButton tooltip={'Send link via Email'} ariaLabel={'send via email'} onClick={(event) => handleSendToCustomer(event, 'EMAIL')} icon={<EmailIcon />} isDisabled={false} />
                <ShareButton tooltip={'Send link via SMS (only available on Mobile Devices)'} ariaLabel={'send via sms'} onClick={(event) => handleSendToCustomer(event, 'SMS')} icon={<SmsIcon />} isDisabled={!mobileCheck()} />
              </InputAdornment>
            }}
          />
        </div>
      </Container>
    </React.Fragment>
  );
}

export default Share;