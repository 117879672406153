import { restEndpoint, apiAuth } from '../config';
import cloneDeep from 'lodash/cloneDeep';
import _ from 'lodash';
import axios from 'axios'

const idRefAttrib = 'idRef';
const trackMeta = false;
const _TEMP_PREFIX = '$_';
const _TEMP_FOREDIT = `${_TEMP_PREFIX}forEdit`

function createApi(token, headers, type = 'json') {

  const newToken = window.RelianceApp.auth.authToken
  let authToken = token === newToken ? token : newToken

  return axios.create({
    baseURL: restEndpoint,
    headers: {
      ...headers,
      Authorization: `Bearer ${authToken}`,
    },
    responseType: type
  })
}

const request = {
  get: (url, params, token, callback) =>
    createApi(token)
      .get(url, params)
      .then(response => callback(decorate(response.data)))
      .catch(error => callback({ status: error && error.response && error.response.status, hasError: true, ...error})),
  getBlob: (url, token, callback) =>
    createApi(token, {}, 'blob')
      .get(url)
      .then(response => callback(response))
      .catch(error => console.log(error)),
  post: (url, params, token, callback, headers) =>
    createApi(token, headers)
      .post(url, params)
      .then(response => callback(decorate(response.data)))
      .catch(error => callback({ status: error && error.response && error.response.status, hasError: true, ...error})),
  getAll: (url, token, type) => createApi(token, {}, type).get(url)
}

export function getTodolist(token, callback) {
  request.get(`${restEndpoint}/r/loans `, {}, token, callback)
}

export function getLoan(token, id, callback) {
  if(id){
    request.get(`${restEndpoint}/r/loans/${id}`, {}, token, callback)
  }
}

export function getLoanStips(token, id, callback) {
  if(id){
    request.get(`${restEndpoint}/r/loans/${id}/stips`, {}, token, callback)
  }
}

export function getMiscellaneous(token, id, callback) {
  if(id){
    request.get(`${restEndpoint}/r/loans/${id}/images?misc=true`, {}, token, callback)
  }
}

export function uploadMiscellaneous(token, id, formData, callback) {
  if(id){
    request.post(`${restEndpoint}/r/loans/${id}/images`, formData, token, callback, { 'Content-Type': 'multipart/form-data' })
  }
}

export function uploadDocumentStips(token, id, stipId, formData, callback) {
  if(id){
    request.post(`${restEndpoint}/r/loans/${id}/stips/${stipId}/images`, formData, token, callback, { 'Content-Type': 'multipart/form-data' })
  }
}

export function getUploadedFile(token, key, callback) {
  if(key){
    request.getBlob(`${restEndpoint}/r/imaging/${key}`, token, callback)
  }
}

export function getPrequal(token, id, callback) {
  if(id){
    request.getBlob(`${restEndpoint}/r/loans/${id}/images/prequal`, token, callback)
  }
}

export async function getEmailTemplates(token, templates, callback) {
	if(templates){
		let reqAll = []
		templates.forEach(template => reqAll.push(request.getAll(`${restEndpoint}/r/email/templates/${template}`, token, {}, 'arraybuffer')))

		await axios.all(reqAll).then(results => {
			let assignedData = {}
			results.forEach((res) => {
				if (res.status === 200) {
					assignedData = Object.assign(assignedData, {[res.data.type.toLowerCase()]: res.data })
				}
			})
			callback(assignedData)
		})
	}
}

export function sendEmail(token, templateName, formData, callback) {
  request.post(`${restEndpoint}/r/email/templates/${templateName}`, formData, token, callback, { 'Content-Type': 'application/json' })
}

export function sendLoanEmail(token, loanId, templateName, formData, callback) {
  request.post(`${restEndpoint}/r/email/${loanId}/templates/${templateName}`, formData, token, callback, { 'Content-Type': 'application/json' })
}

export function getAffordabilityCalc(params, callback) {
  axios.get(`${restEndpoint}/affordabilityCalc`, { params: params })
    .then(response => callback(decorate(response.data)))
    .catch(error => callback({ status: (error && error.response && error.response.status) || {}, hasError: true}))
}

export function getEnums(enumNames, callback) {
  axios.get(`${restEndpoint}/affordabilityCalc/enums?names=${enumNames.join(',')}`)
    .then(response => callback(decorate(response.data)))
    .catch(error => callback({ status: (error && error.response && error.response.status) || {}, hasError: true}))
}

export function getBanner(token, callback) {
  request.get(`${restEndpoint}/r/banner`, {}, token, callback)
}

function convertToBase64(res) {
  return `data:${res.headers['content-type']};base64,${res.data}`;
}

export async function getAvatar(token, userIds, callback) {
  if(userIds){ 
    let reqAll = []
     
    userIds.forEach(user => {
      reqAll.push(request.getAll(`${restEndpoint}/r/users/${user}/avatar`, token, {}, 'arraybuffer'))
    })

    await axios.all(reqAll).then(results => {
      let assignedIDs = {}
      results.forEach((res, idx) => {
        if (res.status === 200) {
          assignedIDs = Object.assign(assignedIDs, {[`${userIds[idx]}`]: convertToBase64(decorate(res))})
        }
      })

      callback(assignedIDs)
    })
  }
}

export function getKeycloakAccount(token, callback) {
  request.get(`${restEndpoint}/r/account`, {}, token, callback)
}

export function getUser(token, callback) {
  request.get(`${restEndpoint}/r/account/user`, {}, token, callback)
}

export function updateUser(token, id, formData, callback) {
  request.post(`${restEndpoint}/r/account/user/${id}`, formData, token, callback, { 'Content-Type': 'application/json', 'accept': 'application/json' })
}

export function updateKeycloakAccount(token, formData, callback) {
  request.post(`${restEndpoint}/r/account`, formData, token, callback, { 'Content-Type': 'application/json', 'accept': 'application/json' })
}

export function configureMfa(token, formData, callback) {
  request.post(`${restEndpoint}/r/account/mfa`, formData, token, callback, { 'Content-Type': 'application/json' })
}

export function getPasswordPolicies(token, callback) {
  request.get(`${apiAuth.url}/realms/${apiAuth.realm_client}/rfc_account/pwdPolicies`, {}, token, callback)
}

function decorate(entity) {
  if (entity) {
    var origJSONStr = trackMeta && JSON.stringify(entity);
    _resolveIdentities(entity);
    //* Set original json to the object for use in patch operations.
    if (trackMeta) {
      entity.___meta = {
        origJSON: origJSONStr
      };
    }
  }
  return entity;
}

//TODO later: should we move these kinds of methods to model-utils behavior? Seems like it make sense to me.
function _resolveIdentities(mainObject) {
  //setup resolving jackson json identities
  //this is lazy load so it could be faster than replacing directly
  var idMap = new Map();
  //collect ids
  _visit(mainObject, function(o) {
    if (o && o[idRefAttrib]) {
      idMap.set(o[idRefAttrib], o);
    }
  });

  //replace id with object.
  _visit(mainObject, function(o, parent, parentProp) {
    if (parentProp === idRefAttrib) {
      return;
    }
    if (idMap.has(o)) {
      parent[parentProp] = idMap.get(o);
    }
  });
}

//taken from rfc-ajax
function _visit(o, visitor, options) {
  options = options || { childFirst: false };
  var descended = new Map();
  let visit = function(o, visitor, parent, parentProp) {
    if (!options.childFirst) {
      //rfc-ajax needs the ability to visit the same object multiple times(replacing with idRefs)
      //but just does not descend into already descended objects.
      visitor(o, parent, parentProp);
    }

    if (!descended.has(o)) {
      descended.set(o, o);
      if (Array.isArray(o)) {
        for (let i = 0; i < o.length; i++) {
          visit(o[i], visitor, o, i);
        }
      } else if (typeof(o) === "object" || Array.isArray(o)) {
        for (let i in o) {
          visit(o[i], visitor, o, i);
        }
      }
    }

    if (options.childFirst) {
      visitor(o, parent, parentProp);
    }
  }
  visit(o, visitor);
}

export function unDecorate(entity) {
  if (entity) {
    var res = cloneDeep(entity);
    if (trackMeta) {
      delete res['___meta'];
    }
    _unresolveIdentities(res);
    removeTempData(res);
    return res;
  }
}

function _unresolveIdentities(mainObject) {
  //setup resolving jackson json identities
  //this is lazy load so it could be faster than replacing directly
  var idMap = new Map();

  //replace object with id.
  _visit(mainObject, function(o, parent, parentProp){
    if(parentProp === idRefAttrib){
      return;
    }
    if(o && o[idRefAttrib]){
      if(!idMap.has(o[idRefAttrib])){
        //id is not yet seen, store and use object
        idMap.set(o[idRefAttrib], o);
      }else if(parent && isPropertyName(parentProp)){
        //id seen? replace object
        parent[parentProp] = o[idRefAttrib];
      }
    }
  });
}

function removeTempData(mainObject) {
  _removeForEditTempData(mainObject);
  _visit(mainObject, function(o, parent, parentProp){
    if (parentProp && typeof(parentProp) == "string" && parentProp.startsWith(_TEMP_PREFIX)) {
      delete parent[parentProp];
    }
  });
  return mainObject;
}

function _removeForEditTempData(mainObject) {
  let isForEditTempData = function (obj){
    if(!(obj !== null && typeof(obj) === "object" && obj.$_forEdit)) return false;
    var orig = typeof(obj.$_forEdit) === "object" ? obj.$_forEdit : undefined;
    //console.log("isForEditTempData", obj);
    var props = Object.getOwnPropertyNames(obj);
    for(var i=0; i<props.length; i++){
      var prop = props[i];
      if(!prop.startsWith('$_')){
        //has orig, and it changed
        if(orig !== undefined && orig.hasOwnProperty(prop)){
          if(!_.isEqual(obj[prop], orig[prop])){
            return false;
          }
        }else{
          var val = obj[prop];
          if (!isValueEmpty(val)){
            return false;
          }
        }
      }
    }
    return true;
  };
  _visit(mainObject, function(o, parent, parentProp){
    if(parentProp === _TEMP_FOREDIT) return;
    //lets not process at this level since we cant properly do filtering(indices gets affected during deletion).
    if(Array.isArray(parentProp)) return;
    // var hasProp = false;
    if(Array.isArray(o)){
      //is array, filter out ForEditTempData elements
      o = o.filter(function(e){return !isForEditTempData(e)});
      if (parent && isPropertyName(parentProp)) {
        parent[parentProp] = o;
      }
    }else{
      if(isForEditTempData(o)){
        delete parent[parentProp];
      }
    }
  }, {childFirst: true});
  return mainObject;
}

function isPropertyName(val) {
  return val != null && val !== "";
}

function isValueEmpty(val) {
  return val === "" || val === null || val === undefined || val === 0 || (Array.isArray(val) && val.length === 0);
}