import React from 'react';
import ReactDOM from 'react-dom';
import { apiAuth } from './config'
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { getLocalizedString } from './behaviors/LocalizeBehavior';
import { keycloakInit } from "./behaviors/RfcAuthBehavior";

// Retrieve localized messages
getLocalizedString();

window.RelianceApp.auth = keycloakInit(apiAuth.url, apiAuth.realm_client, apiAuth.realm_client, apiAuth.token_store, apiAuth.redirect_base, 
  window.cordova ? 'safariViewController' : 'default', () => {
  	let debug = false;
    ReactDOM.render(
      <App debug={debug}></App>, document.getElementById('root')
    );
    // If you want your app to work offline and load faster, you can change
    // unregister() to register() below. Note this comes with some pitfalls.
    // Learn more about service workers: https://bit.ly/CRA-PWA
    serviceWorker.unregister();
});
