import Keycloak from 'keycloak-js/dist/keycloak';

export function keycloakInit(url, realm, clientId, tokenStore, redirectBase, adapter, onLoginCallback) {
    
    var auth = {};

    try {
        auth.keycloak = new Keycloak({
            url: url,
            realm: realm,
            clientId: clientId,
        });
        auth.tokenStore = tokenStore;
        auth.redirectBase = redirectBase;
    } catch (e) {
        // this.fire('error', "Failed to initialize Keycloak.");
        console.log(e);
        return;
    }

    auth.keycloak.onTokenExpired = refresh;
    document.addEventListener('login-view-closed', onLoginViewClosed);
    //this.keycloak.onAuthRefreshError = this._refreshFailed.bind(this);            

    //only works when set to keycloak....
    if (auth.redirectBase) {
        auth.keycloak.redirectBase = auth.redirectBase;
    }

    var oldState = retrieve(auth.tokenStore);
    var initParams = {
        onLoad: 'login-required',
        flow: 'standard',
        pkceMethod: 'S256',
        adapter: adapter,
    };
    if (auth.checkLoginIframe) {
        initParams.checkLoginIframe = auth.checkLoginIframe === "true"
    }

    auth.keycloak.init(Object.assign(initParams, oldState)).success(authenticated => {
        onLoginSuccess(authenticated, onLoginCallback);
    }).error(onLoginFailed);

    function onLoginSuccess(authenticated, callback) {
        if (authenticated) {
            auth.authToken = auth.keycloak.token;
            callback();
            store(auth);
            // this.fire('login-success');
            //during reload, refresh and access token is set to be the same.
            //Force a refresh to get an actual longer refresh token
            if (auth.keycloak.token === auth.keycloak.refreshToken) {
                console.log("Forcing a token refresh after browser refresh.");
                refresh(-1);
            }
            //add username cookie for nginx logs
            document.cookie = "u=" + auth.keycloak.tokenParsed.preferred_username + ";path=/";
        }
    }

    function refresh(expiresIn) {
        return auth.keycloak.updateToken(expiresIn)
            .success(refreshed => {
                if (refreshed) {
                    auth.authToken = auth.keycloak.token;
                    store(auth);
                    // this.fire('token-refreshed');
                }
            }).error(onRefreshFailed);
    }

    function onRefreshFailed(e) {
        console.log(e, "Failed to refresh token.")
        // this.fire('error', "Failed to refresh token.");
    }

    function onLoginFailed(e) {
        console.log(e, "Login Failure.")
        // this.fire('error', this._getErrorMessage(e, "Login Failure."));
    }

    function onLoginViewClosed(e) {
        console.log('Cur URL', window.location.href);
        window.setTimeout(() => {
            /**
             * FIXME: This does not work for Android yet. The goal is to display a Login Cancelled
             * message when login view controller is closed.
             * 
             * Its wrapped in setTimeout because the event is fired by Android on successful authentication
             * prior to keycloak processing the authentication. This causes Login Cancelled to be displayed
             * even on successful authentication. 
             */
            if (!auth.keycloak || !auth.keycloak.authenticated) {
                this.fire('error', "Login Cancelled.");
            }
        }, 500);
    }
    
    return auth;
}

export function isTokenExpired(keycloak) {
    return keycloak ? keycloak.isTokenExpired() : false;
}

export function getErrorMessage(e, def) {
    return (e && decodeURIComponent(e.error_description).replace(/\+/g, " ")) || def;
}

export function logout(options, tokenStore, keycloak) {
    clearFromStore(tokenStore);
    if (keycloak) {
        keycloak.logout(options);
    }
}

function store(auth) {
    if (auth.tokenStore === 'SESSION_STORAGE') {
        sessionStorage.setItem('authData', JSON.stringify({
            token: auth.authToken,
            //using accessToken to refresh does not work anymore.
            refreshToken: auth.keycloak.refreshToken,
            timeSkew: auth.keycloak.timeSkew
        }));
    }
}

function retrieve(tokenStore) {
    // this.fire('intent', 'test');
    return (tokenStore === 'SESSION_STORAGE' && JSON.parse(sessionStorage.getItem('authData') || '{}')) || {};
}

function clearFromStore(tokenStore) {
    if (tokenStore === 'SESSION_STORAGE') {
        sessionStorage.removeItem('authData');
    }
}
