import React, { useState, useContext, useEffect, useCallback, useRef } from 'react';
import withWidth from '@material-ui/core/withWidth';
import { MortgageCalculator, MortgageChart, Schedule, numberInputFormat, ToastMessageContext, LogoPrint, TodoContext, localize, VideoLink } from 'cnc-component-library'
import { getAffordabilityCalc, getEnums } from '../behaviors/RfcAjaxBehavior'
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import _ from 'lodash'
import Typography from '@material-ui/core/Typography';
import ErrorIcon from '@material-ui/icons/Error';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import html2canvas from 'html2canvas'
import { jsPDF } from "jspdf";
import autoTable from 'jspdf-autotable'
import { PDFDocument } from 'pdf-lib'
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    minHeight: '500px',
  },
  header: {
    color: theme.palette.text.primary,   
    [`${theme.breakpoints.up('sm')}`]: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    [`${theme.breakpoints.down('xs')}`]: {
      '&.MuiPaper-root': {
        padding: '4px'
      }
    }
  },
  disclaimer: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    margin: '0 auto',
    justifyContent: 'center',
    '& svg': {
      marginRight: '5px'
    },
    textAlign: 'left'
  },
  investorDisclaimer: {
    display: 'flex',
    padding: theme.spacing(0, 1),
    justifyContent: 'left',
    textAlign: 'left'
  },
  rateMessage: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1),
    width: '90%',
    margin: '0 auto',
    justifyContent: 'center',
    '& svg': {
      marginRight: '5px'
    },
    height: '300px'
  },
  schedule: {
    [`${theme.breakpoints.down('sm')}`]: {
      display: 'block',
      flexWrap: 'nowrap'
    }
  },
  chartWrapper: {
    [`${theme.breakpoints.down('sm')}`]: {
      minHeight: '300px',
    }
  },
  stickToBottom: {
    width: '100%',
    position: 'fixed',
    bottom: 0,
  },
  toggleSchedule: {
    margin: '1em 0 1.25em 0'
  },
  totalPayment: {
    marginTop: '1em'
  },
  totals: {
    justifyContent: 'space-between',
    pointerEvents: 'none'
  },
  totalGroups: {
    margin: '1em 0',
    width: '100%',
    [`${theme.breakpoints.up('sm')}`]: {
      width: 600,
    }
  },
  disclaimerWrapper: {
    display: 'flex',
  },
  paperInput: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 300,
    marginRight: 10,
    marginTop: -5
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  offsetCalculator: {
    width: 1024, 
    margin: '0 auto', 
    position: 'fixed', 
    zIndex: -99, 
    left: -3000,
    '&.noTax .property-tax, &.noTax .home-insurance': {
      display: 'none'
    }
  }
}));

const initialChartValue = {
  "insurance": "59.00",
  "taxes": "15.00",
  "principalAndInterest": "99.00",
  "yourPayment": "0"
}

const initialValue = {
  homeprice: 300000,
  percentage: 20,
  term: 360,
  creditScore: 720,
  product: 'Conventional',
  occupancyType : 'PrimaryResidence',
  interestRate: 0,
  insurance: 600,
  hoadues: 0,
  downpayment: {
    amount: 60000,
    percentage: 20
  },
  taxes: {
    amount: 1800,
    percentage: 0.6
  },
  termList: [
    { name: '30-year Fixed', value: 360 },
    { name: '25-year Fixed', value: 300 },
    { name: '20-year Fixed', value: 240 },
    { name: '15-year Fixed', value: 180 },
    { name: '10-year Fixed', value: 120 }
  ],
  productList: [
    { name: 'Conventional', value: 'Conventional' },
    { name: 'FHA', value: 'FHA' },
    { name: 'VA', value: 'VA' },
    { name: 'USDA', value: 'FarmersHomeAdministration' },
    { name: 'Alternative Income', value: 'AlternativeIncome' },
    { name: 'Investor Advantage', value: 'InvestorAdvantage' }
  ],
  occupancyTypes: [
    { name: 'Owner Occupied', value: 'PrimaryResidence' },
    { name: 'Second Home', value: 'SecondHome' },
    { name: 'Investment', value: 'Investor' },
  ],
  showTax: true
}

function isOdd(num) { return (num % 2) === 1;}

const reOrder = (arr) => {
  const newData = arr.slice().map(item => ({...item, itemsLength: arr.length}))
  newData.sort((a, b) => a.value - b.value)

  let result = [];

  let a = 0,
    b = newData.length - 1;

  while (result.length < newData.length) {
    result.push(newData[b]);
    result.push(newData[a]);

    a++;
    b--;
  }

  if (isOdd(newData.length)) {
    result.pop()
  }
 
  return result
}

const totalPaymentsLabel = 'Total payments:',
totalPrincipalPaymentsLabel = 'Total principal payments:',
totalInterestPaymentsLabel = 'Total interest payments:',
amortizationScheduleLabel = 'Amortization Schedule'

const createChartData = (data, showTax, noChartData = false) => {

  let newData = [];

  const total = noChartData ? Math.floor(Math.random() * 100) + 10 : Number(data.yourPayment)
  const interest = noChartData ? Math.floor(Math.random() * 100) + 10 : Number(data.principalAndInterest)
  const taxes = noChartData ? Math.floor(Math.random() * 100) + 10 : Number(data.taxes)
  const pmi = noChartData ? Math.floor(Math.random() * 100) + 10 : Number(data.pmi)
  const mmip = noChartData ? Math.floor(Math.random() * 100) + 10 : Number(data.mmip)
  const insurance = Number(data.insurance)

  const pmiIncluded = data.pmiIncluded && pmi > 0

  if (data.principalAndInterest !== '--' && Number(data.principalAndInterest) > 0 ) {
    newData.push({ name: 'principalAndInterest', labelValue: interest, value: interest, title:"P&I", total: total, pmiIncluded: pmiIncluded, noChartData: noChartData })
  }

  if ((data.insurance !== '--' && Number(data.insurance) > 0) && showTax) {
    newData.push({ name: 'insurance', labelValue: Number(data.insurance), value: insurance, title:"Insurance", total: total, pmiIncluded: pmiIncluded, noChartData: noChartData })
  }

  if ((data.taxes !== '--' && Number(data.taxes) > 0) && showTax) {
    newData.push({ name: 'taxes', labelValue: taxes, value: taxes, title:"Tax", total: total, pmiIncluded: pmiIncluded, noChartData: noChartData })
  }

  if (data.pmiIncluded) {
    newData.push({ name: 'pmi', labelValue: pmi, value: pmi, title:"PMI", total: total, pmiIncluded: pmiIncluded, noChartData: noChartData })
  }

  if (data.mmip !== '--' && Number(data.mmip) > 0) {
    newData.push({ name: 'mmip', labelValue: mmip, value: mmip, title:"MMIP", total: total, pmiIncluded: pmiIncluded, noChartData: noChartData })
  }

  const sorted = reOrder(newData)
  
  return sorted
}

const steps = ['Calculator', amortizationScheduleLabel]

function Calculator({ width, stickyHeader, formSubmitted, handlePdfBytes }) {
  const toastMessage = useRef(useContext(ToastMessageContext));
  const state = useRef(useContext(TodoContext));
  const debounceOnChange = useCallback(_.debounce(handleDataChange, 400), []);
  
  const classes = useStyles();

  const [terms, setTerms] = useState([])
  const [products, setProducts] = useState([])
  const [occTypes, setOccTypes] = useState([])
  const [interestRate, setInterestRate] = useState(0)
  const [params, setParams] = useState(initialValue)
  const [rateMessage, setRateMessage] = useState('')

  const [chartData, setChartData] = useState([])

  const [activeStep, setActiveStep] = useState(0);
  const [schedule, setSchedule] = useState(false)
  const [advanced, setAdvanced] = useState(false)
  const [pmiIncluded, setPmiIncluded] = useState(false)
  const [resetCopy, setResetCopy] = useState(true)
  const [isInvestor, setInvestor] = useState(false)
 
  const docRef = useRef(null)
  const tableRef = useRef(null)
  const svgDocRef = useRef(null)

  const calcTitle =  localize('calc.title', 'Affordability Calculator for Purchases');

  const handleDoc = () => {
    const pdfCalc = new jsPDF({
      orientation: 'landscape'
    });


    if (svgDocRef.current) {
      html2canvas(svgDocRef.current, { scrollY: -window.scrollY })
      .then(canvas => {
        const svgData = canvas.toDataURL('image/png', 1.0);
        pdfCalc.addImage(svgData, 'PNG', 42, 10, 220, 20);
      })
    }

    html2canvas(docRef.current, { scrollY: -window.scrollY })
      .then(async(canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const imgWidth = 290;  
        const imgHeight = canvas.height * imgWidth / canvas.width;

        pdfCalc.text(calcTitle, 100, 40)
        pdfCalc.addImage(imgData, 'PNG', 14, 42, imgWidth, imgHeight);

        pdfCalc.setFillColor(108, 15, 23)
        pdfCalc.circle(15.8, 190, 2, 'F');

        pdfCalc.setTextColor('white');
        pdfCalc.setFontSize(10)
        pdfCalc.text(15.3, 191.3, '!');

        const pmiNote = localize('calc.note.pmi');
        const investorDisclaimer = localize('calc.note.ppp');
        const disclaimer = localize('calc.note');

        if (pmiIncluded) {
          pdfCalc.setFontSize(16)
          pdfCalc.setTextColor(108, 15, 23)
          pdfCalc.text('*', 15, 185)

          pdfCalc.setFontSize(9)
          pdfCalc.setTextColor('black')
          pdfCalc.text(20, 183, pdfCalc.splitTextToSize(pmiNote, (280-14-14)))
        }

        pdfCalc.setFontSize(9)
        pdfCalc.setTextColor('black')
        pdfCalc.text(20, 190, pdfCalc.splitTextToSize(disclaimer, (280-14-14)))

        if(isInvestor) {
          pdfCalc.setFontSize(16)
          pdfCalc.setTextColor(108, 15, 23)
          pdfCalc.text('*', 15, 185)

          pdfCalc.setFontSize(9)
          pdfCalc.setTextColor('black')
          pdfCalc.text(20, 183, pdfCalc.splitTextToSize(investorDisclaimer, (280-14-14)))
        }

        const pdfCalcBytes = pdfCalc.output('arraybuffer')

        if (pdfCalcBytes) {

          const pdfSchedule = new jsPDF({
            orientation: 'landscape',
          });
          
          pdfSchedule.text(amortizationScheduleLabel, 14, 22)

          autoTable(pdfSchedule, {
            startY: 30,
            showHead: false,
            body: [
              [totalPaymentsLabel, { content: `$${numberInputFormat(schedule.totalPayments, 2, 2)}`, styles: { halign: 'right' }}],
              [totalPrincipalPaymentsLabel, { content: `$${numberInputFormat(schedule.totalPrincipal, 2, 2)}`, styles: { halign: 'right' }}],
              [totalInterestPaymentsLabel, { content: `$${numberInputFormat(schedule.totalInterest, 2, 2)}`, styles: { halign: 'right' }}],
            ],
            tableLineColor: [108, 15, 23],
            bodyStyles: {
              textColor: [108, 15, 23],
              lineColor: [108, 15, 23],
            },
            columnStyles: {
              payments: {
                halign: 'right'
              }
            },
            theme: 'grid',
          })

          autoTable(pdfSchedule, { 
            html: '#schedule-table',
            startY: 58,
            headStyles: { fillColor: '#8A0A0A' },
            columnStyles: { 0: { cellWidth: 20 }, 1: { cellWidth: 50 }, 2: { cellWidth: 50 } },
            didParseCell: function (data) {      
              if (data.column.dataKey !== 0) {
                data.cell.styles.halign = 'right'
              }
            },
          })

          const pdfScheduleBytes = pdfSchedule.output('arraybuffer')
          const schedulePdfDoc = await PDFDocument.load(pdfScheduleBytes)
          const calcPdfDoc = await PDFDocument.load(pdfCalcBytes)
          const pdfDoc = await PDFDocument.create();
          const [calcPage] = await pdfDoc.copyPages(calcPdfDoc, [0])
          const schedulePage = await pdfDoc.copyPages(schedulePdfDoc, schedulePdfDoc.getPageIndices())
          
          pdfDoc.addPage(calcPage)
          schedulePage.forEach(page => {
            pdfDoc.addPage(page)
          })

          const pdfBytes = await pdfDoc.saveAsBase64()

          if (pdfBytes) {
            handlePdfBytes(pdfBytes)
          }
        }
    }) 
  }

  useEffect(() => {
    let mounted = true
    getEnums(["termMonths", "products", "occupancyTypes"], response => {
      if (response.hasError) {
        if (mounted) {
          setTerms(params.termList); 
          setProducts(params.productList)
          setOccTypes(params.occupancyTypes)
        }
      } else {
        const { termMonths, products, occupancyTypes } = response

        let terms = []
        termMonths.forEach(item => {
          const name =  localize('calc.term.' + item), value = item
          terms.push({ name, value})
        })

        let calcProducts = []
        products.forEach(item => {
          const name =  localize('calc.product.' + item), value = item
          calcProducts.push({name,  value})
        })

        let occTypes = []
        occupancyTypes.forEach(item => {
          const name =  localize('calc.occType.' + item), value = item
          occTypes.push({name,  value})
        })
 
        if (mounted) { 
          setTerms(terms);
          setProducts(calcProducts)
          setOccTypes(occTypes)
        }
      }
    });
    return () => {
      mounted = false
    }
  }, []);

  useEffect(() => {
    let mounted = true

    let newParams = {
      homeprice: params.homeprice,
      term: params.term,
      product: params.product,
      insurance: params.insurance,
      hoadues: params.hoadues,
      downpayment: params.downpayment.amount,
      taxes: params.taxes.amount,
      creditScore: params.creditScore,
      occupancyType: params.occupancyType
    }

    setInvestor(params.product === 'InvestorAdvantage');

    const { setToastError } = toastMessage.current


    if (!params.showTax) {
      delete newParams.taxes
      delete newParams.insurance
    }

    state.current.setTodoLoading(true)
    setResetCopy(false)

    getAffordabilityCalc(newParams, response => {
      if (response.hasError) {
        const chartData = createChartData(initialChartValue, true, true)
        setToastError(response)
        if (mounted) setChartData(chartData)
      } else {
        if (mounted) setInterestRate(response.interestRate)
        if (response.rateMessage) {
          if (mounted) setRateMessage(response.rateMessage)
        }
        
        if (response.schedule) {
          if (mounted) setSchedule(response.schedule)
        }

        const chartData = createChartData({ ...response.chart, pmiIncluded: response.pmiIncluded }, params.showTax)
        if (mounted) {
          setPmiIncluded(response.pmiIncluded)
          setChartData(chartData)
        }
      }
      state.current.setTodoLoading(false)
      setResetCopy(true)

    })
    return () => {
      mounted = false
    }
  }, [params])

  useEffect(() => {
    let mounted = true

    if (mounted && formSubmitted) {
      handleDoc()
    }

    return () => {
      mounted = false
    }
  }, [formSubmitted])
  
  function handleDataChange(data) {
    setParams(data)
    // setResetCopy(false)
  }

  const handleStep = (step) => () => {
    setActiveStep(step);
  };
  
  const handleAdvanced = () => {
    setAdvanced(!advanced)
  }

  const calculatorProps = {
    ...params,
    advanced: advanced,
    handleAdvanced: handleAdvanced,
    handleDataChange: debounceOnChange,
    terms: terms,
    products : products,
    interestRate: interestRate,
    occTypes: occTypes
  }

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Typography variant="h6" className={classes.header}>
          {<VideoLink url="https://youtu.be/lQdlZnaOnIc" title="Tutorial" style={{paddingRight: '5px', display: width !== 'xs' ? 'flex' : 'inline', position: 'relative', top: width === 'xs' ? '5px' : 0}}/>}
          { calcTitle }
        </Typography>
      <Tabs
          value={activeStep}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleStep}
          aria-label="tabs"
          centered
        >
          {
            steps.map((label, index) => (
              <Tab label={label} key={label} onClick={handleStep(index)} disabled={index !== 0 && !schedule && interestRate === '--'} />
            ))
          }
        </Tabs>
        <Grid container spacing={1} justifyContent="center">   
          { activeStep === 0 && <React.Fragment>
            { (width === 'md' || width === 'lg' || width === 'xl') && 
              <Grid item xs={12} md={6} >
                <MortgageCalculator {...calculatorProps} />
              </Grid> }
            <Grid item xs={12} sm={12} md={6} className={classes.chartWrapper}>
              {
                interestRate !== '--' ?
                <React.Fragment>
                  <div>
                    <MortgageChart data={chartData} width={width} />
                  </div>
                  <div className={clsx(classes.offsetCalculator, params.showTax ? '' : 'noTax')}>
                    <div ref={svgDocRef}>
                    <LogoPrint />
                    </div>
                    <div ref={docRef} style={{display: 'flex' }}>
                      {resetCopy && <MortgageCalculator {...calculatorProps} toPdf={true} />}
                      <MortgageChart data={chartData} width={width} toPdf={true} />
                    </div>
                    <div ref={tableRef}>
                      {schedule && <Schedule data={schedule} toPrint={true} stickyHeader={false} />}
                    </div>
                  </div>
                </React.Fragment>:
                <Typography variant="body2" gutterBottom className={classes.rateMessage}>
                  <ContactSupportIcon color="primary" />
                  { rateMessage ? rateMessage : 'Rate is not available, please contact us at 877-271-3082.' }
                </Typography>
              }
            </Grid>
            { (width === 'xs' || width === 'sm') && <Grid item xs={12} sm={12} md={4} >
                <MortgageCalculator {...calculatorProps} />
              </Grid>}

            { isInvestor &&  <Grid item xs={12} >
                <div className={classes.disclaimerWrapper}>
                  <Typography variant="h6" color="primary" style={{marginLeft: '8px', marginRight: '9px', position: 'relative', bottom: '2px'}}> * </Typography> 
                  <Typography variant="body2" className={classes.investorDisclaimer}>
                  {localize('calc.note.ppp')}
                  </Typography>
                </div>
              </Grid>}

            {<Grid item xs={12} >
              <div className={classes.disclaimerWrapper}>
                <ErrorIcon color="primary" />
                <Typography variant="body2" gutterBottom className={classes.disclaimer}>
                  {localize('calc.note')}
                </Typography>
              </div>
            </Grid>}

          </React.Fragment>}


          { activeStep === 1 && 
            <Grid item xs={12}>
              <ButtonGroup className={classes.totalGroups} color="primary" aria-label="outlined primary button group" orientation={"vertical"}>
                <Button fullWidth className={classes.totals}>{totalPaymentsLabel} <b>${numberInputFormat(schedule.totalPayments, 2, 2)}</b></Button>
                <Button fullWidth className={classes.totals}>{totalPrincipalPaymentsLabel} <b>${numberInputFormat(schedule.totalPrincipal, 2, 2)}</b></Button>
                <Button fullWidth className={classes.totals}>{totalInterestPaymentsLabel} <b>${numberInputFormat(schedule.totalInterest, 2, 2)}</b></Button>
              </ButtonGroup>
              <Schedule data={schedule} stickyHeader={stickyHeader} />
            </Grid>
          }          
        </Grid>   
      </Paper>
    </div> 
 );
}

export default withWidth()(Calculator);