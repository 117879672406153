import React, { useState, useEffect, useContext, useRef, useCallback } from 'react';
import clsx from 'clsx';
import { Link } from "react-router-dom";
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import withWidth from '@material-ui/core/withWidth';
import Drawer from '@material-ui/core/Drawer';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import { pages } from "../behaviors/RfcContext"
import Container from '@material-ui/core/Container';
import Pipeline from './Pipeline'
import Calculator from './Calculator'
import Loan from './Loan'
import UserProfile from './UserProfile'
import { TopBar, TodoContext, ToastMessageContext, history, UserProfileContextSetters, PdfSend, localize } from 'cnc-component-library';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import { getKeycloakAccount, getEmailTemplates, sendEmail } from '../behaviors/RfcAjaxBehavior'
import { AuthTokenContext } from '../behaviors/RfcContext';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import Share from './Share';
import _ from 'lodash'
import SmsIcon from '@material-ui/icons/Sms';
import EmailIcon from '@material-ui/icons/Email';

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    height: '100vh',
    position: 'relative'
  },
  title: {
    flexGrow: 1,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  toolbar: {
    display: 'block',
    minHeight: '64px'
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    minHeight: '70px'
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(7),
    },
  },
  list: {
    width: 250
  },
  fullList: {
    width: 'auto',
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto'
  },
  toastError: {
    '& .MuiSnackbarContent-root ': {
      backgroundColor: 'red',
      color: '#FFF'
    }
  },
  toastSuccess: {
    '& .MuiSnackbarContent-root ': {
      backgroundColor: 'green',
      color: '#FFF'
    }
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  nav: {
    minWidth: '40px',
    '& .MuiListItemIcon-root': {
      minWidth: '40px'
    }
  },
}));

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip);

const Realtor = props => {
  const authToken = useContext(AuthTokenContext);
  const userProfile = useContext(UserProfileContextSetters)
  const toastMessage = useContext(ToastMessageContext);
  const state = useRef(useContext(TodoContext));
  const { onLogout, page, width } = props;
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [toast, setToast] = useState({
    open: false,
    message: '',
    toastClass: null
  })
  const [stickyHeader, setStickyHeader] = useState(false)
  const containerRef = useRef(null)

  const [swipe, setSwipe] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const [formContent, setFormContent] = useState({})
  const [formSubmitted, setFormSubmitted] = useState(false)
  const [formLoading, setFormLoading] = useState(false)
  const [pdfBytes, setPdfBytes] = useState(null)
  const [templateName, setTemplateName] = useState(null)
  const [formType, setFormType] = useState(null)
  const [hasTemplate, setHasTemplate] = useState(false)
  const defaultMessage = useRef(null)

  const isCalculatorPage = page===pages[1].key;

  const getRealtorTemplates = (isMounted) => {
    const templates = ['RealtorAffordabilityCalculator','RealtorAffordabilityCalculatorSMS']
    getEmailTemplates(authToken, templates, response => {
      if (response.hasError) {
        toastMessage.current.setToastError(response)
      } else {
        actionData.forEach(action => {
          defaultMessage.current = {
            ...defaultMessage.current,
            [action.type]: response[action.type].body
          }

          if (isMounted) {
            setFormContent(prev => ({
              ...prev,
              [action.type]: { recipient: '', body: response[action.type].body}
            }))
          }
        })

        setHasTemplate(true)
      }
    })
  }

  useEffect(() => {
    let mounted = true
    if(isCalculatorPage) {
      getRealtorTemplates(mounted)
    }

    return () => {
      mounted = false
    }
  }, [])

  useEffect(() => {
    let mounted = true

    if (mounted) {
      toastMessage.resetToast();
      if (toastMessage.error) {
        setToast({ open: true, message: toastMessage.errorMessage, toastClass: classes.toastError })
      } else if (toastMessage.success) {
        setToast({ open: true, message: toastMessage.successMessage, toastClass: classes.toastSuccess })
      }
    }

    return () => (mounted = false)
  }, [toastMessage.error, toastMessage.success, classes])

  useEffect(()  => {
    let mounted = true
    if (page !== 'userProfile')
      getKeycloakAccount(authToken, user => {   
        if (mounted) 
          userProfile.setUserProfile(user)      
      });
    return () => (mounted = false)
  }, [authToken, userProfile, page])
  
  const handleDrawerOpen = () => {
    setOpen(!open);
  };

  const handleClose = () => {
    setToast({...toast, open: false});
  }

  const MenuTooltip = (props) => {
    const { pages, page, index } = props
    const content = (
      <ListItem button key={page.key} component={Link} to={pages[index].link}>
        <ListItemIcon>{page.icon}</ListItemIcon>
        <ListItemText primary={page.label} />
      </ListItem>
    );
    return (
      <React.Fragment>
        {
          page.tooltip ? 
          <HtmlTooltip
            title={
              <React.Fragment>
                <Typography color="inherit" variant="subtitle2">{ page.tooltip }</Typography>
              </React.Fragment>
            }
          > 
            { content }
          </HtmlTooltip>
          : content
        }
      </React.Fragment>
    )
  }

  const drawer = (
    <div>
      <div className={classes.toolbarIcon}>
        </div>
      <Divider />
      <List className={classes.nav}>
        {pages.map((page, index) => (
          pages[index].link !== null ?
            <MenuTooltip key={`${page}_${index}`} pages={pages} page={page} index={index} />
          : null
        ))}
      </List>
    </div>
  );

  const toggleDrawer = (anchor, open) => (event) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setSwipe({ ...swipe, [anchor]: open });
  };

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List className={classes.nav}>
        {pages.map((page, index) => (
          pages[index].link !== null ?
          <ListItem button key={page.key} component={Link} to={pages[index].link}>
            <ListItemIcon>{page.icon}</ListItemIcon>
            <ListItemText primary={page.label} />
          </ListItem> : null
        ))}
      </List>
    </div>
  );

  const debounceHandleUpdates = useCallback(_.debounce((formState, type) => {
    setFormContent(prev => ({
      ...prev,
      [type]: { recipient: formState.recipient, body: formState.body}
    }))
  }, 500), [])

  const sendForm = (templateName, type) => {
    state.current.setTodoLoading(true)
    setFormLoading(true)
    setFormSubmitted(true)

    const formData = {
      ...formContent[type],
      base64Attachment: pdfBytes
    }

    sendEmail(authToken, templateName, formData,response => {
      state.current.setTodoLoading(false)
      setFormLoading(false)
      setFormSubmitted(false)

      if (response.hasError) {
        toastMessage.setToastError(response)
      } else {
        const bodyMsg = _.get(defaultMessage, `current.${type}`, "");
        if (_.isEmpty(bodyMsg)) {
          getRealtorTemplates(true)
        } else {
          setFormContent(prev => ({
            ...prev,
            [type]: { recipient: '', body: bodyMsg}
          }))
        }
        toastMessage.setToastSuccess(response[0]);
      }
    })
  }

  useEffect(() => {
    if (pdfBytes) {
      sendForm(templateName, formType)
    }
  }, [pdfBytes])

  const handleSubmit = (templateName, type) => {
    setFormSubmitted(true)
    setFormLoading(true)
    setTemplateName(templateName)
    setFormType(type)
  }

  const menu = [
    { name: "myProfile", label: "My Profile", func: () => history.push('/userProfile'), icon: <AccountBoxIcon fontSize="small" /> },
    { name: "logout", label: "Logout", func: onLogout, icon: <ExitToAppIcon fontSize="small" /> }
  ]

  const openDrawer = width === 'xs' ? toggleDrawer('left', true) : handleDrawerOpen

  useEffect(() => {
    const handleScroll = () => { 
      if (containerRef.current.scrollTop > 200 && page === pages[1].key) {
        setStickyHeader(true)
      } else {
        setStickyHeader(false)
      }
    }
    
    if (page === pages[1].key) window.addEventListener('scroll', handleScroll, { capture: true })
    return () => {
      window.removeEventListener('scroll', handleScroll, { capture: true })
    }
  }, [page, containerRef])

  const actionData = [
    {
      name: 'email',
      type: 'email',
      title: localize("realtor.calc.email", "Email Affordability Calculator"),
      subTitle: localize("realtor.calc.email.sub", "Please complete the To line and change/fill-in any text for the email and the calculator will be sent as an attachment with this email to your customer."),
      icon: <EmailIcon />,
      iconPosition: 96,
      snackbar: {
        style: { bottom: '100px'},
        message: localize('realtor.form.sendViaEmail', 'Send via Email'),
      },
      formState: {
        recipient: formContent?.email?.recipient,
        body: formContent?.email?.body,
      },
      handleUpdates: (data, type) => {
        debounceHandleUpdates(data, type)
      },
      handleSubmit: () => {
        handleSubmit('RealtorAffordabilityCalculator', 'email')
      }
    },
    {
      name: 'sms',
      type: 'sms',
      title: localize("realtor.calc.sms", "SMS Affordability Calculator"),
      subTitle: localize("realtor.calc.sms.sub",  "Please complete the To line and change/fill-in the mobile number and the link containing the calculator will be sent as sms to your customer."),
      icon: <SmsIcon />,
      iconPosition: 160,
      snackbar: {
        style: { bottom: '162px'},
        message: localize('realtor.form.sendViaSms', 'Send via SMS')
      },
      formState: {
        recipient: formContent?.sms?.recipient,
        body: formContent?.sms?.body,
      },
      handleUpdates: (data, type) => {
        debounceHandleUpdates(data, type)
      },
      handleSubmit: () => {
        handleSubmit('RealtorAffordabilityCalculatorSMS', 'sms')
      }
    },
  ]

  return (
    <React.Fragment>
        <Grid container direction="column" justifyContent="flex-start" alignItems="stretch" className={`${classes.root}`}>
          <div className={classes.root}>
            <CssBaseline />
            <TopBar handleDrawerOpen={openDrawer} menu={menu} />
            {
              width === 'xs' ?
              <SwipeableDrawer
                anchor={'left'}
                open={swipe['left']}
                onClose={toggleDrawer('left', false)}
                onOpen={toggleDrawer('left', true)}
              >
                {list('left')}
              </SwipeableDrawer> :
              <Drawer
                variant="permanent"
                className={clsx(classes.drawer, {
                  [classes.drawerOpen]: open,
                  [classes.drawerClose]: !open,
                })}
                classes={{
                  paper: clsx({
                    [classes.drawerOpen]: open,
                    [classes.drawerClose]: !open,
                  }),
                }}
              >
                {drawer}
              </Drawer>
            }
            
            <main className={classes.content} ref={containerRef}>
              <div className={classes.appBarSpacer} />
              <Container maxWidth="xl" className={classes.container}>
                <Grid container spacing={1}>
                  {page === pages[0].key ? <Pipeline></Pipeline> : null}
                  {page === pages[1].key
                    ? <Calculator
                        formSubmitted={formSubmitted}
                        stickyHeader={stickyHeader}
                        handlePdfBytes={bytes => setPdfBytes(bytes)}
                      />
                    : null
                  }
                  {page === pages[2].key ? <Loan loanId={props.loanId}></Loan> : null}
                  {page === pages[4].key ? <UserProfile></UserProfile> : null}
                  {page === pages[5].key ? <Share></Share> : null}
                </Grid>
              </Container>
            </main>
          </div>
        </Grid>

        {isCalculatorPage && hasTemplate && (
          <PdfSend
            {...{formLoading, formSubmitted}}
            tooltip="Send Affordability Calculator"
            actions={actionData}
          />
        )}

        <Snackbar
          className={toast.toastClass}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          open={toast.open}
          autoHideDuration={6000}
          onClose={handleClose}
          message={toast.message}
          key={`vertical + horizontal`}
          action={
            <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
                <CloseIcon fontSize="small" />
              </IconButton>
          }
        />
    </React.Fragment>
  );
}

Realtor.propTypes = {
    classes: PropTypes.object,
    width: PropTypes.oneOf(['lg', 'md', 'sm', 'xl', 'xs']).isRequired,
};

export default withWidth()(Realtor);