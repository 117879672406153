import React, { useContext, useEffect, useState, useRef } from 'react';
import withWidth from '@material-ui/core/withWidth';
import { getTodolist, getBanner } from "../behaviors/RfcAjaxBehavior";
import {  AuthTokenContext } from '../behaviors/RfcContext';
import { SearchFilter, TodoList, TodoContext, ToastMessageContext, sortBy, filterBy, isArrayEqual, Banner } from 'cnc-component-library';
import { useHistory } from 'react-router-dom';
import _ from 'lodash'

const Pipeline = props => {
  const state = useRef(useContext(TodoContext));
  const toastMessage = useContext(ToastMessageContext);
  const history = useHistory()
  let authToken = useContext(AuthTokenContext);

  const [todoList, setTodoList] = useState({})
  const [query, setQuery] = useState('')
  const [filters, setFilters] = useState({ascending: true, sortIndex: 0})

  const [banner, setBanner] = useState({})

  const stopLoading = () => {
    state.current.setTodoLoading(false)
  }
  
  useEffect(() => {
    // loading on first load
    let mounted = true
    if (_.isEmpty(state.current.todo) && mounted) state.current.setTodoLoading(true)
    // Retrieve Todolist
    getTodolist(authToken, todolist => {        
      if (todolist && todolist.length > 0) {
        const loanIds = todolist.reduce(function(acc, cur, i) {
          acc[cur.id] = cur;
          return acc;
        }, {});
        
        let sorted = sortBy(todolist, filters.ascending, filters.sortIndex)

        if (mounted) setTodoList(sorted)

        const equal = isArrayEqual(
          state.current.todo.records,
          sorted
        );
        
        // if not equal update records
        if (!equal) {
          if (query !== '') {
            const filtered = filterBy(sorted, query)
            if (mounted) state.current.setTodo({records: filtered, loans: loanIds})
          } else {
            if (mounted) state.current.setTodo({records: sorted, loans: loanIds})
          }
        }
      } else if (todolist.length === 0) {
        if (mounted) {
          toastMessage.setToastError('No Records Found.')
        }         
      } else {
        if (mounted) {
          toastMessage.setToastError(todolist)
        } 
      }

      if (mounted) {
        //Moved stopLoading here to ensure that loading is stopped when getTodolist gets response
        stopLoading()
      }
    });

    // Retrieve Banner
    getBanner(authToken, fetchedBanner => {        
      if (mounted) setBanner(fetchedBanner);
    });

    return () => {
      mounted = false
      stopLoading()
    }
  // remove query or filters to avoid reloading
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authToken])

  const handleSearch = (query, filters) => {
    setQuery(query)
    setFilters(filters)
  }

  return (
    <React.Fragment>
      {
        // hide loan details on mobile transition
        props.width === 'xs' && 
          <style jsx="true" global="true">{`      
              .grid-loan-details {
                display: none;
              }
          `}</style>
      }
      <Banner banner={banner} />
      <SearchFilter data={todoList} handleSearch={handleSearch} />
      <TodoList history={history} />
    </React.Fragment>
  );
}

export default withWidth()(Pipeline);