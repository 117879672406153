import React, { useState } from 'react';
import { Router, Route, Switch } from "react-router-dom";
import resolveUrl from 'resolve-url';
import Realtor from './views/Realtor'
import { logout } from "./behaviors/RfcAuthBehavior";
import { AuthTokenContext, LoanIdContext } from './behaviors/RfcContext';
import Page404 from './views/Page404';
import './App.css';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { TodoContextProvider, ToastMessageContextProvider, theme, history, UserProfileContextProvider, AddToHomescreen } from 'cnc-component-library'
import { MotionLayoutProvider } from "react-motion-layout";
import Document from './views/Document'

function App(props) {

  const [values] = useState({...props, auth: window.RelianceApp.auth});

  /**
   * Handles the on-logout event to logout keycloak auth service
   * @param {e} e Object with a payload for the options
   * needed by keycloak
   *
   */
  const logoutAndGoHome = () => {
    var logoutOptions = {
      redirectUri: window.cordova && window.location.href.indexOf('/www/') >= 0 ? 'comrfccp://oauth/' : resolveUrl(process.env.PUBLIC_URL)
    };
    logout(logoutOptions, values.auth.tokenStore, values.auth.keycloak);
  }

  return (
    <React.Fragment>
      <AuthTokenContext.Provider value={values.auth.authToken}>
        <UserProfileContextProvider>
        <ToastMessageContextProvider>
          <LoanIdContext.Provider value={values.loanId}>
            <TodoContextProvider>
              <MuiThemeProvider theme={theme}>
              <MotionLayoutProvider>
              <Router history={history} >
                <React.Suspense fallback={<div>Loading...</div>}>
                  <Switch>
                    {/* <Route exact path="/" render={() => (<Redirect to="/loans" />)} /> */}
                    <Route exact path="/" component={(props) => <Realtor onLogout={logoutAndGoHome} {...props} page="loans" debug={values.debug} />} />
                    <Route exact path="/calculator" component={(props) => <Realtor onLogout={logoutAndGoHome} {...props} page="calculator" debug={values.debug} />} />
                    <Route exact path="/loan/:loanId" component={(props) => <Realtor onLogout={logoutAndGoHome} {...props} page="loan" loanId={props.match.params.loanId} debug={values.debug} />} />
                    <Route exact path="/loan/:loanId/:selectedTab" component={(props) => <Realtor onLogout={logoutAndGoHome} {...props} page="loan" loanId={props.match.params.loanId} debug={values.debug} />} />
                    <Route exact path="/userProfile" component={(props) => <Realtor onLogout={logoutAndGoHome} {...props} page="userProfile" debug={values.debug} />} />
                    <Route exact path="/document/:key" component={() => <Document />}></Route>
                    <Route exact path="/share" component={(props) => <Realtor onLogout={logoutAndGoHome} {...props} page="share" debug={values.debug} />} />
                    <Route component={() => <Page404 />}></Route>
                  </Switch>
                </React.Suspense>
              </Router>
              </MotionLayoutProvider>
              {/* <Realtor onLogout={logoutAndGoHome}></Realtor> */}
              </MuiThemeProvider>
            </TodoContextProvider>
          </LoanIdContext.Provider>
        </ToastMessageContextProvider>
        </UserProfileContextProvider>
      </AuthTokenContext.Provider>
      <AddToHomescreen />
    </React.Fragment>
  );
}

export default App;