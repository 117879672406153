import React, { useContext, useState, useEffect, useRef } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { getLoan, getLoanStips, uploadDocumentStips, getMiscellaneous, uploadMiscellaneous, getPrequal, getAvatar } from "../behaviors/RfcAjaxBehavior";
import { AuthTokenContext } from '../behaviors/RfcContext';
import { TodoItem, TabPanel, Stips, PDFViewer, TodoContext, ToastMessageContext, Contacts, Tracker, localize } from 'cnc-component-library'
import { Link, Switch, Route, Redirect, useParams, useLocation } from 'react-router-dom';
import styled from "styled-components";
import { MotionScene, SharedElement, MotionScreen } from "react-motion-layout";
import SendPrequalButton from '../components/SendPrequalButton';
import _ from 'lodash'

const StyledTab = styled(({ ...otherProps }) => (
  <Tab
    {...otherProps}
    classes={{ root: "root" }}
  />
))`
  & .labelContainer {
    padding: 0;
  }

  &.root {
    min-width: 40px;
    padding: 0 1em!important;
  }
`;

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
  },
  tabItem: {
    fontWeight: 'bold',
    padding: '0 1em',
    '& .root': {
      padding: '0 1em'
    }
  }
}));

export const Loan = ({ width }) => {
  const authToken = useContext(AuthTokenContext);
  const state = useRef(useContext(TodoContext));
  const toastMessage = useContext(ToastMessageContext);
  const location = useLocation();
  const { loanId, selectedTab } = useParams()
  let redirectTimer = useRef(null)
  const loanData = _.get(location, 'state.selectedLoan', null)
  const [selectedLoan, setSelectedLoan] = useState(loanData);
  const [loading, setLoading] = useState(true);
  const [stips, setStips] = useState({});
  const [misc, setMisc] = useState({});
  const classes = useStyles();

  const theme = useTheme();
  const [tab, setTab] = useState(0);
  const [pdf, setPDF] = useState(null)
  const [hasPrequalDoc, setHasPrequalDoc] = useState(false);
  const [avatars, setAvatars] = useState({})
  const [toRedirect, setRedirect] = useState(false)

  const isNull = (val) => val === null
 
  useEffect(() => {
    let mounted = true
    
    if(!loanData) state.current.setTodoLoading(true)
    getLoan(authToken, loanId, response => {       
      if (response.hasError) {
        if (response.status === 404) {
          if (mounted) toastMessage.setToastError('No Records Found.')
          redirectTimer.current = setTimeout(setRedirect(true), 1000)
        } else {
          if (mounted) toastMessage.setToastError(response)
        }
      } else {
        if (mounted && !_.isEqual(response, selectedLoan))
          setSelectedLoan(response);
      }

      if (mounted) setLoading(false)
      if(!loanData && mounted) state.current.setTodoLoading(false)
    });
    
    return () => {
      if (redirectTimer.current) clearTimeout(redirectTimer.current)
      mounted = false
    }
  }, [authToken, loanId, loanData])


  useEffect(() => { 
    let mounted = true

    if (!selectedTab) {
      if (mounted) setTab(0)

      // Get Stips
      getLoanStips(authToken, loanId, response => {
        if (response.hasError) {
          if (mounted) toastMessage.setToastError(response)
        } else {
          if (mounted) setStips(response);
        }
      });

      // Get Miscellaneous
      getMiscellaneous(authToken, loanId, response => {
        if (response.hasError) {
          if (mounted) toastMessage.setToastError(response)
        } else {
          if (mounted) setMisc(response);
        }
      });
    }

    if (selectedTab === 'pre-approval') {
      if (mounted) setTab(1)
      getPrequal(authToken, loanId, response => {
        if (response.hasError) {
          if (mounted) toastMessage.setToastError(response)
        } else {
          const file = new Blob(
            [response.data], 
            {type: 'application/pdf'}
          );
          const fileURL = URL.createObjectURL(file);

          if (mounted) {
            setPDF(fileURL)
            setHasPrequalDoc(_.get(response, "data.size", 0)>0)
          }
        }
        
        if (mounted) state.current.setTodoLoading(false)
      })   
    }

    if (selectedTab === 'contacts') {
      if (mounted) setTab(2)

      if (selectedLoan) {
        const { additionalInfo } = selectedLoan
        const { assignedMortgageAnalyst,  assignedProcessor } = additionalInfo
  
          if (additionalInfo) {
            const mortgageAnalyst = assignedMortgageAnalyst ? assignedMortgageAnalyst.id : null
            const processor = assignedProcessor ? assignedProcessor.id : null
          
            let assigned = []
            if (!isNull(mortgageAnalyst)) assigned.push(mortgageAnalyst)
            if (!isNull(processor)) assigned.push(processor)
          
            getAvatar(authToken, assigned, response => {
              if (mounted) setAvatars(response)
            })
            
          }
      }
    }

    return () => {
      if (redirectTimer.current) clearTimeout(redirectTimer.current)
      mounted = false
    }

  }, [authToken, loanId, selectedTab, selectedLoan]);

  const handleUploads = (stipId, files) => {
    let formData = new FormData();
    formData.append('images', files)
    state.current.setTodoLoading(true)
    uploadDocumentStips(authToken, loanId, stipId, formData, response => {
      if (response.hasError) {
        toastMessage.setToastError(response)
      } else {
        let newStips = stips.slice()
        let index = _.findIndex(newStips, {id: response.id});
        newStips.splice(index, 1, response);
        setStips(newStips);
      }

      state.current.setTodoLoading(false) 
    });
  }

  const handleMisc = (files) => {
    let formData = new FormData();
    formData.append('images', files)
    state.current.setTodoLoading(true)
    uploadMiscellaneous(authToken, loanId, formData, response => {   
      if (response.hasError) {
        toastMessage.setToastError(response)
      } else {
        let newMisc = misc.slice()
        newMisc.unshift(response[0])
        setMisc(newMisc.reverse());
      }  

      state.current.setTodoLoading(false)
    });
  }

  const viewFile = (key, filename) => {
    window.open(`${process.env.PUBLIC_URL}/document/${key}`, '_blank')
  }

  // animate only if from loans
  const animatedLoan = location && location.state && location.state.isLoanPage ? 
                              <TodoItem {...selectedLoan} /> : 
                              <SharedElement.Div animationKey="loan">
                                <TodoItem {...selectedLoan} />
                              </SharedElement.Div>

  if (toRedirect) return <Redirect to={"/"} />
  return <div className={classes.root}>
                <MotionScreen>
                  <MotionScene
                    name={`loan-${loanId}`}
                  >
                    {animatedLoan}
                  </MotionScene>
                </MotionScreen>
                <Tracker {...selectedLoan} />
                <AppBar position="static" color="default">
                <Tabs
                  value={tab}
                  indicatorColor="primary"
                  textColor="primary"
                  variant={width === 'xs' ? "fullWidth" : "standard"}
                  aria-label="scrollable force tabs"
                >
                    <StyledTab label="Documents Needed" component={Link} 
                      to={{
                        pathname: `/loan/${loanId}`,
                        state: { selectedLoan, isLoanPage: true }
                      }} 
                      {...a11yProps(0)} className={classes.tabItem}  />
                    <StyledTab label="Pre-Approval" component={Link}
                    to={{
                      pathname: `/loan/${loanId}/pre-approval`,
                      state: { selectedLoan, isLoanPage: true }
                    }} 
                    {...a11yProps(1)} className={classes.tabItem} />
                    <StyledTab label="Contacts" component={Link}
                    to={{
                      pathname: `/loan/${loanId}/contacts`,
                      state: { selectedLoan, isLoanPage: true }
                    }} 
                   {...a11yProps(2)} className={classes.tabItem} />
                </Tabs>
                </AppBar>
                <Switch>
                  <React.Fragment>
                    <Route path={`/loan/${loanId}`} render={() => 
                      <TabPanel value={tab} index={0} dir={theme.direction} isScrollable={true} isBoxed={true}>
                        <Stips key={`stips`} stips={stips} loading={loading} handleUploads={handleUploads} misc={misc} handleMisc={handleMisc} viewFile={viewFile} />
                      </TabPanel> 
                    } />

                    <Route path={`/loan/${loanId}/pre-approval`} render={() => 
                      <TabPanel value={tab} index={1} dir={theme.direction}>
                        <PDFViewer
                          pdf={pdf}
                          errorMessage={localize('mobile.label.preApproval.preApprovalNotIssued', 'Pre-Approval has not yet been issued.')}
                          loanId={loanId}
                        />
                      </TabPanel> 
                    } />

                    <Route exact path={`/loan/${loanId}/contacts`} render={() => 
                      <TabPanel value={tab} index={2} dir={theme.direction} isScrollable={true} isBoxed={true}>
                        {selectedLoan && <Contacts {...selectedLoan} avatars={avatars} />}
                      </TabPanel>
                    } />
                  </React.Fragment>
                </Switch>

                {(selectedTab==='pre-approval' && hasPrequalDoc) && (
                  <SendPrequalButton loanId={loanId} />
                )}
              </div>
}

export default withWidth()(Loan);