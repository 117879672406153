import React, { useState, useContext, useEffect, useRef } from 'react'
import { PDFViewer, localize } from 'cnc-component-library'
import { useParams, useLocation } from 'react-router-dom';
import { getUploadedFile, getPrequal } from "../behaviors/RfcAjaxBehavior";
import { AuthTokenContext } from '../behaviors/RfcContext';
import { getMobileOS } from 'cnc-component-library'

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function Document() {
  const { key } = useParams()
  const query = useRef(useQuery())
  const authToken = useContext(AuthTokenContext);
  const [pdfKey, setPDFKey] = useState(null)
  const isAndroid = getMobileOS() === 'Android'

  

  useEffect(() => {
    const launchDocument = (response) => {
      const file = new Blob(
        [response.data], 
        {type: 'application/pdf'}
      );
      const fileURL = URL.createObjectURL(file);
      if (isAndroid) setPDFKey(fileURL);
      else window.location.href = fileURL;
    }

    if (query.current.get("isPrequal")) {
      getPrequal(authToken, key, response => launchDocument(response))
    } else {
      getUploadedFile(authToken, key, response => launchDocument(response))
    }

  }, [authToken, key, isAndroid])

  return (
    <React.Fragment>
      {isAndroid && <PDFViewer pdf={pdfKey} errorMessage={localize('mobile.message.documentNotFound', 'Document not found.')} standAlone={true} />}
    </React.Fragment>
  )
}

export default Document
